.container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-backgrounds-gray);
}
.Main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.Hero {
  padding-bottom: var(--dl-space-space-twounits);
}
.max-width {
  align-items: stretch;
}
.content-container {
  flex: 1;
  display: flex;
  min-width: 50%;
  align-items: flex-start;
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
}
.text {
  width: 100%;
  font-size: 3.25em;
  font-style: normal;
  font-family: Lexend;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  text-transform: none;
  text-decoration: none;
}
.text02 {
  color: var(--dl-color-backgrounds-darkblue1);
}
.Banner {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-grays-dark100);
  border-style: solid;
  padding-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
  border-top-width: 2px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
}
.text03 {
  font-size: 2rem;
  text-align: center;
}
.text04 {
  color: var(--dl-color-grays-dark100);
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.BtnGroup {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.button {
  color: #FFFFFF;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.button:hover {
  transform: scale(1.02);
}
.link {
  color: black;
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: white;
}
.link:hover {
  transform: scale(1.02);
}
.features-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.features-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.feature {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
}
.icon {
  fill: #62BF77;
  width: 17px;
  height: 17px;
  min-width: 17px;
  margin-right: 12px;
}
.text09 {
  color: var(--dl-color-grays-dark80);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.feature1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
}
.icon02 {
  fill: #62BF77;
  width: 17px;
  height: 17px;
  min-width: 17px;
  margin-right: 12px;
}
.text10 {
  color: var(--dl-color-grays-dark80);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.feature2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.icon04 {
  fill: #62BF77;
  width: 17px;
  height: 17px;
  min-width: 17px;
  margin-right: 12px;
}
.text11 {
  color: var(--dl-color-grays-dark80);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.image-container {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.Image {
  width: 95%;
  opacity: 1;
  object-fit: cover;
  margin-left: 45px;
  border-radius: 3%;
}
.SectionOne {
  padding-top: var(--dl-space-space-fiveunits);
  border-color: rgba(33, 33, 33, 0.1);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.Stats {
  width: 100%;
  display: flex;
  padding: 0px;
  max-width: var(--dl-size-size-maxwidth);
  justify-content: center;
}
.Stat {
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.icon06 {
  fill: #999999;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.text12 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.text13 {
  color: #999999;
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.text14 {
  font-size: 3rem;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 300;
}
.Stat1 {
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.icon09 {
  fill: #999999;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.text16 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.text18 {
  color: #999999;
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.text20 {
  text-decoration: underline;
}
.text22 {
  font-size: 3rem;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 300;
}
.Stat2 {
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.icon11 {
  fill: #999999;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.text24 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.text25 {
  color: #999999;
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.text26 {
  font-size: 3rem;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 300;
}
.max-width1 {
  padding-top: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.text28 {
  width: 100%;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.cards-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.Card {
  flex: 1;
  width: 25%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.image01 {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-twounits);
}
.text30 {
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.text31 {
  color: var(--dl-color-grays-gray100);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.link1 {
  color: var(--dl-color-backgrounds-darkblue1);
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 600;
  line-height: 1.75;
  text-decoration: none;
}
.link1:hover {
  text-decoration: underline;
}
.Card1 {
  flex: 1;
  width: 25%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.image02 {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-twounits);
}
.text33 {
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.text34 {
  color: var(--dl-color-grays-gray100);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.text35 {
  color: var(--dl-color-backgrounds-darkblue1);
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 600;
  line-height: 1.75;
}
.text35:hover {
  text-decoration: underline;
}
.Card2 {
  flex: 1;
  width: 25%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.image03 {
  width: 70px;
  height: 70px;
  object-fit: cover;
  margin-bottom: 22px;
}
.text36 {
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.text37 {
  color: var(--dl-color-grays-gray100);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.text38 {
  color: var(--dl-color-backgrounds-darkblue1);
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 600;
  line-height: 1.75;
}
.text38:hover {
  text-decoration: underline;
}
.Card3 {
  flex: 1;
  width: 25%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.image04 {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-twounits);
}
.text39 {
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.text40 {
  color: var(--dl-color-grays-gray100);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.text41 {
  color: var(--dl-color-backgrounds-primary);
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 600;
  line-height: 1.75;
}
.text41:hover {
  text-decoration: underline;
}
.max-width2 {
  flex-direction: row;
  justify-content: flex-start;
}
.image-container1 {
  flex: 1;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.image05 {
  width: 100%;
  max-width: 640px;
  object-fit: cover;
  margin-left: -100px;
}
.content-container1 {
  flex: 1;
  display: flex;
  max-width: 446px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.text42 {
  margin-bottom: 24px;
}
.text44 {
  color: var(--dl-color-backgrounds-primary);
}
.text45 {
  color: var(--dl-color-grays-gray100);
}
.Features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.text48 {
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-threeunits);
}
.text50 {
  color: var(--dl-color-backgrounds-darkblue1);
}
.text51 {
  color: var(--dl-color-backgrounds-darkblue1);
}
.container1 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr;
}
.Testimonial {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #36393F;
}
.Testimonial1 {
  width: 100%;
  display: flex;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: var(--dl-color-grays-discordgray);
}
.container2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.text52 {
  color: #FFFFFF;
  margin-bottom: var(--dl-space-space-threeunits);
}
.container3 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr 1fr;
}
.max-width3 {
  flex-direction: row;
  justify-content: flex-start;
}
.content-container2 {
  flex: 1;
  display: flex;
  max-width: 446px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.text54 {
  margin-bottom: 24px;
}
.text56 {
  color: var(--dl-color-backgrounds-primary);
}
.text57 {
  color: var(--dl-color-grays-gray100);
  padding-bottom: 50px;
}
.image-container2 {
  flex: 1;
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.image06 {
  width: 100%;
  object-fit: cover;
  margin-left: 85px;
}
.max-width4 {
  flex-direction: column;
  justify-content: center;
}
.heading-container {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.text60 {
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.text63 {
  color: var(--dl-color-backgrounds-primary);
}
.text66 {
  color: var(--dl-color-grays-gray100);
}
.get-the-app {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
}
.image07 {
  width: 151px;
  object-fit: cover;
  margin-right: var(--dl-space-space-unit);
}
.image08 {
  width: 151px;
  object-fit: cover;
}
.cards-container1 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr;
}
.SectionSix {
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
  /*padding-bottom: 0px;*/
  background-color: var(--dl-color-grays-discordgray);
}
.max-width5 {
  align-items: stretch;
}
.content-container3 {
  flex: 1;
  display: flex;
  min-width: 60%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.text68 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.text70 {
  color: var(--dl-color-grays-white100);
}
.text71 {
  color: var(--dl-color-grays-white80);
  margin-bottom: 15px;
}
.input-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.container4 {
  width: 100%;
  height: 56px;
  display: flex;
  max-width: 376px;
  align-items: center;
  line-height: 1.5;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 32px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
}
.icon14 {
  fill: var(--dl-color-grays-gray40);
  width: 20px;
  height: 20px;
}
.textinput {
  flex: 1;
  color: var(--dl-color-grays-gray40);
  border-width: 0px;
  padding-left: 12px;
  padding-right: 12px;
}
.button1 {
  display: flex;
  transition: 0.3s;
  align-items: center;
  line-height: 1;
  padding-top: 20px;
  white-space: nowrap;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 20px;
  justify-content: center;
  background-color: var(--dl-color-grays-dark100);
}
.button1:hover {
  background-color: var(--dl-color-grays-white100);
}
.features-container1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: auto;
}
.feature3 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
  width: auto;
}
.icon16 {
  fill: var(--dl-color-grays-white100);
  width: 17px;
  height: 17px;
  min-width: 17px;
  margin-right: 12px;
}
.text72 {
  color: var(--dl-color-grays-white80);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  width: auto;
}
.feature4 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
  width: auto;
}
.icon18 {
  fill: var(--dl-color-grays-white100);
  width: 17px;
  height: 17px;
  min-width: 17px;
  margin-right: 12px;
}
.text73 {
  color: var(--dl-color-grays-white80);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  width: auto;
}
.feature5 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: auto;
}
.icon20 {
  fill: var(--dl-color-grays-white100);
  width: 17px;
  height: 17px;
  min-width: 17px;
  margin-right: 12px;
}
.text74 {
  color: var(--dl-color-grays-white80);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  width: auto;
}
.image-container3 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.image09 {
  width: 100%;
  max-width: 589px;
  min-width: 550px;
  object-fit: contain;
}
@media(max-width: 991px) {
  .max-width {
    flex-direction: column;
  }
  .content-container {
    margin-bottom: 42px;
    padding-right: 0px;
  }
  .Image {
    max-width: 100%;
    margin-left: 0px;
  }
  .image05 {
    max-width: 100%;
    margin-left: 0px;
  }
  .content-container1 {
    margin-bottom: 42px;
    padding-right: 0px;
  }
  .text42 {
    width: 100%;
  }
  .text52 {
    text-align: center;
  }
  .container3 {
    grid-template-columns: 1fr;
  }
  .content-container2 {
    margin-bottom: 42px;
    padding-right: 0px;
  }
  .text54 {
    width: 100%;
  }
  .image06 {
    max-width: 100%;
    margin-left: 0px;
  }
  .max-width5 {
    flex-direction: column;
  }
  .text68 {
    width: 100%;
  }
  .image09 {
    max-width: 100%;
  }
}
@media(max-width: 767px) {
  .Banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .text04 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .Stats {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .Stat {
    padding: var(--dl-space-space-unit);
  }
  .text12 {
    text-align: center;
  }
  .Stat1 {
    padding: var(--dl-space-space-unit);
  }
  .text16 {
    text-align: center;
  }
  .Stat2 {
    padding: var(--dl-space-space-unit);
  }
  .text24 {
    text-align: center;
  }
  .text28 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .cards-container {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .Card {
    width: 50%;
  }
  .Card1 {
    width: 50%;
  }
  .Card2 {
    width: 50%;
  }
  .Card3 {
    width: 50%;
  }
  .max-width2 {
    flex-direction: column;
  }
  .image-container1 {
    width: 100%;
    margin-right: 0px;
  }
  .image05 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .content-container1 {
    max-width: 100%;
  }
  .Features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .container1 {
    grid-template-columns: 1fr;
  }
  .container2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .max-width3 {
    flex-direction: column-reverse;
  }
  .content-container2 {
    max-width: 100%;
  }
  .image-container2 {
    width: 100%;
    margin-right: 0px;
  }
  .image06 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .text60 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .cards-container1 {
    grid-template-columns: 1fr;
  }
  .image09 {
    min-width: 100%;
  }
}
@media(max-width: 479px) {
  .Banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .BtnGroup {
    flex-direction: column;
  }
  .link {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
  .Stats {
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .cards-container {
    justify-content: center;
  }
  .Card {
    width: 100%;
  }
  .Card1 {
    width: 100%;
  }
  .Card2 {
    width: 100%;
  }
  .Card3 {
    width: 100%;
  }
  .Features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .container2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .input-container {
    flex-direction: column;
  }
  .container4 {
    max-width: 100%;
    margin-bottom: var(--dl-space-space-unit);
  }
}
