.Footer {
  border-color: rgba(33, 33, 33, 0.1);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.max-width {
  justify-content: space-between;
}
.container {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.image {
  width: 106px;
  margin-bottom: var(--dl-space-space-twounits);
}
.text {
  color: var(--dl-color-grays-gray100);
  max-width: 350px;
  margin-bottom: 60px;
}
.SocialMedia {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.text01 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
.container1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.icon {
  width: 24px;
  height: 24px;
  transition: 0.3s;
  margin-right: var(--dl-space-space-twounits);
}
.icon:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}
.icon04 {
  width: 24px;
  height: 24px;
  transition: 0.3s;
  margin-right: var(--dl-space-space-twounits);
}
.icon04:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}
.icon08 {
  width: 24px;
  height: 24px;
  transition: 0.3s;
  margin-right: var(--dl-space-space-twounits);
}
.icon08:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}
.icon10 {
  width: 24px;
  height: 24px;
  transition: 0.3s;
}
.icon10:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}
.Links {
  flex: 0 0 auto;
  width: 40%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.container2 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.text02 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-threeunits);
}
.text07 {
  margin-bottom: 0px;
}
.container3 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.text08 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-threeunits);
}
.text13 {
  margin-bottom: 0px;
}
@media(max-width: 991px) {
  .max-width {
    flex-direction: column-reverse;
  }
  .container {
    width: 100%;
  }
  .Links {
    width: 100%;
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 479px) {
  .Links {
    flex-wrap: wrap;
  }
  .container2 {
    width: 100%;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .text02 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .container3 {
    width: 100%;
  }
  .text08 {
    margin-bottom: var(--dl-space-space-twounits);
  }
}
