.TestimonialCard {
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  background: #fff;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.icon {
  width: 3.5rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.Testimonial {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.text {
  color: #595959;
  align-self: center;
  text-align: center;
}
.text1 {
  font-size: 1.5rem;
  margin-top: var(--dl-space-space-threeunits);
  font-weight: 300;
  margin-bottom: var(--dl-space-space-halfunit);
}
.text2 {
  color: #595959;
  height: var(--dl-size-size-small);
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
}
.image {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}



@media(max-width: 991px) {
  .text2 {
    align-self: center;
  }
}
@media(max-width: 767px) {
  .TestimonialCard {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .TestimonialCard {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .icon {
    margin-bottom: var(--dl-space-space-unit);
  }
  .text {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .text1 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .text2 {
    margin-bottom: var(--dl-space-space-unit);
  }
}
