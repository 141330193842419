.NavbarContainer {
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.Logo {
  display: flex;
  align-items: center;
}
.image {
  width: 100px;
  object-fit: cover;
  border-color: var(--dl-color-grays-dark100);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
}
.text {
  font-size: 45px;
  padding-left: var(--dl-space-space-halfunit);
}
.Nav {
  display: flex;
}
.icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.Nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.Container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.Logo1 {
  display: flex;
  align-items: center;
}
.image1 {
  width: 100px;
  object-fit: cover;
  border-color: var(--dl-color-grays-dark100);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
}
.text1 {
  font-size: 45px;
  padding-left: var(--dl-space-space-halfunit);
}
.MenuClose {
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.PrimaryBtn {
  margin-top: var(--dl-space-space-oneandhalfunits);
  background-color: var(--dl-color-backgrounds-darkblue);
}
.SocialMedia {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.text2 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.container1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.icon04 {
  width: 24px;
  height: 24px;
  transition: 0.3s;
  margin-right: var(--dl-space-space-twounits);
}
.icon04:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}
.icon08 {
  width: 24px;
  height: 24px;
  transition: 0.3s;
  margin-right: var(--dl-space-space-twounits);
}
.icon08:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}
.icon12 {
  width: 24px;
  height: 24px;
  transition: 0.3s;
  margin-right: var(--dl-space-space-twounits);
}
.icon12:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}
.icon14 {
  width: 24px;
  height: 24px;
  transition: 0.3s;
}
.icon14:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}
.PrimaryBtn1 {
  background-color: var(--dl-color-backgrounds-darkblue);
}
@media(max-width: 767px) {
  .Nav {
    display: none;
  }
  .PrimaryBtn1 {
      display: none;
  }
}

.show-mobile-menu {
  transform: translateX(0)!important;
}