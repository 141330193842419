.Copyright {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.max-width {
  align-items: stretch;
}
.text {
  color: var(--dl-color-grays-gray100);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
}
.text1 {
  font-style: normal;
  font-weight: 300;
}
.text2 {
  font-style: normal;
  font-weight: 300;
  text-decoration: underline;
}
.text3 {
  font-style: normal;
  font-weight: 300;
}
.text4 {
  font-style: normal;
  font-weight: 300;
  text-decoration: underline;
}
.text5 {
  font-style: normal;
  font-weight: 300;
}
.text6 {
  font-style: normal;
  font-weight: 300;
  text-decoration: underline;
}
.text7 {
  font-style: normal;
  font-weight: 300;
}
@media(max-width: 991px) {
  .max-width {
    flex-direction: column;
  }
}
